// @ts-ignore
import { configure } from '@la-haus/auth-lib/client'

import { UniversalAuthServiceMethods, Settings } from './types'

interface LhAuth {
  signIn: (params: any) => Promise<string>
  signOut: () => Promise<void>
  getSessionToken: () => Promise<string>
}
declare global {
  interface Window {
    '$lhAuth': LhAuth
  }
}

class UniversalAuthService implements UniversalAuthServiceMethods {
  authId?: string
  environment?: string

  constructor (authId, environment) {
    this.authId = authId
    this.environment = environment

    if (this.authId) {
      const settings: Settings = {
        clientId: this.authId,
        isTestingMode: this.environment !== 'buyer-web-front-production'
      }

      if (process.env.NODE_ENV === 'development') settings.authCountry = 'co'

      configure(settings)
    }
  }

  async getSessionToken () {
    const token = await window.$lhAuth.getSessionToken()
    return token
  }

  async signOut () {
    await window.$lhAuth.signOut()
  }

  async signIn ({ redirectUrl, target = '_self', method }) {
    try {
      return await window.$lhAuth.signIn({
        target,
        redirectUri: redirectUrl || window.location.href,
        method
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export default UniversalAuthService
